
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { styles } from '../../public/styles/shared-styles';
import { router,resolveRouterPath } from '../router';
import './firebase-config'; // Ensure this is at the top
import './app-login';
import './app-batting.ts'; // Adjust the path as necessary
import './app-results-fixtures-carousel.js';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

@customElement('app-home')
export class AppHome extends LitElement {
  @state() isSidebarOpen = false;
  @state() isLoggedIn = false;
  @state() firstName = '';
  @state() user: User | null = null;
  @state() clubRunsScored = 0;
  @state() clubWickets = 0;
  @state() clubGames = 0;
  @state() clubCatches = 0;
  @state() userType = '';





  static styles = [
    styles,
    css`
      :host {
        --sidebar-width: 250px;
        --sidebar-background-color: #f1f1f1;
        --sidebar-link-color: black;
        --sidebar-link-hover-color: white;
        --sidebar-link-hover-background-color: #555;
        background-image: url('/assets/images/background.jpg');
        color: black;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        display: block;
      }
      .hamburger {
        position: fixed;
        top: 10px;
        left: 10px;
        z-index: 8;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 24px;
        color: white;
      }
      #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        width: var(--sidebar-width);
        transition: transform 0.3s ease-in-out;
        z-index: 8;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
      }
      #sidebar.open {
        transform: translateX(0%);
      }
      #sidebar a {
        font-size: 18px;
        color: var(--sidebar-link-color);
        text-decoration: none;
        display: block;
        transition: background-color 0.3s, color 0.3s;
      }
      #sidebar a:hover {
        background-color: var(--sidebar-link-hover-background-color);
        color: var(--sidebar-link-hover-color);
      }
      body {
        margin: 0;
        width: 100vw;
        overflow-x: hidden;
      }
      #mainContent {
        transition: margin-left 0.3s;
        padding: 20px;
        box-sizing: border-box;
        width: calc(100% - var(--sidebar-width));
        margin-left: auto;
        margin-right: auto;
      }
      #mainContent.with-sidebar {
        width: calc(100% - var(--sidebar-width));
      }

      .welcome-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: var(--primary-color);
        color: white;
        border-radius: 10px;

        transition: background-color 0.3s;
        position: relative;

        animation: fadeIn 2s ease-in-out;
      }
      .welcome-message {
        font-size: 2rem;
        font-weight: 400;
        margin: 10px 0;
        font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #206e3a;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
      }
      .greeting-icon {
        width: 50px;
        height: 50px;
        background: 👋
        background-size: contain;
        margin-right: 20px;
        animation: bounce 2s infinite;
      }
      .first-stat {
        padding-top: 55px;
      }
      .separator {
        border-bottom: 1px solid #ddd;
        margin: 20px 0;
      }
      .menu-section {
        padding: 10px 20px;
      }
      .menu-section h2 {
        font-size: 18px;
        color: #333;
      }
      .login-bar {
        position: fixed;
        right: 120px;
        top: 50px;
        z-index: 2;
      }
      .user-icon, .logout-button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 18px;
      }
      .hamburger.open {
        position: fixed;
        left: calc(var(--sidebar-width) + 10px);
      }
      .club-stats-container p {
        font-size: 1.2rem;
        color: #333;
        margin: 10px 0;
        font-weight: bold;
        background: white;
        padding: 10px 0;
        border-radius: 20px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: block;
        width: 100%;
        box-sizing: border-box;
      }
      .club-stats-container {
        text-align: center;
        width: 100%;
        max-width: 800px;
        margin: auto;
      }
      .club-stats-container h3 {
        color: #206e3a;
        margin-bottom: 20px;
        font-size: 1.8rem;
      }
      .high-stats {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .sideshow {
        width: 87.5vw;
        max-width: 100vw;
        overflow: hidden;
        margin-left: calc(-50vw + 55%);
        position: relative;
        box-sizing: border-box;

      }
      .sideshow > * {
        max-width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
      }
      .sideshow button {
        padding: 10px 20px;
        border: none;
        background-color: var(--primary-color);
        color: white;
        border-radius: 20px;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
      }
      .sideshow button:hover {
        background-color: darken(var(--primary-color), 10%);
      }
      @keyframes slideIn {
        0% {
          transform: translateY(20px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
      .sideshow .carousel-item {
        animation: slideIn 0.5s ease-out forwards;
      }
      @media (max-width: 768px) {
        .sideshow {
          width: 100vw;
          margin-left: calc(-50vw + 50%);
        }
      }
      .stats-grid {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding: 0 10px;
        padding-top: 20px;
        box-sizing: border-box;
      }
      .stat-card {
        background-color: white;
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        width: 100%;
        color: #206e3a;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .first-stat {
        padding-top: 50px; /* Adjust the value based on your header's height */
      }

      .stat-icon {
        font-size: 1.5rem;
        color: var(--primary-color);
        margin-right: 10px;
      }
      .stat-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
      }
      .stat-value {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--primary-color);
      }
      .stat-label {
        font-size: 0.9rem;
        color: var(--text-color);
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-10px);
        }
        60% {
          transform: translateY(-5px);
        }
      }
    `
  ];


  connectedCallback() {
    super.connectedCallback();
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.isLoggedIn = true;
        this.fetchUserName(user.uid);
        this.fetchClubStats();
        } else {
        this.user = null;
        this.isLoggedIn = false;
        if (window.location.pathname !== resolveRouterPath('home')) {
          router.navigate(resolveRouterPath('login'));
        }
      }
    });
  }
  async fetchClubStats() {
    const db = getFirestore();
    const statsRef = doc(db, "Club/Stats");
    try {
      const docSnap = await getDoc(statsRef);
      if (docSnap.exists()) {
        const statsData = docSnap.data();
        this.clubRunsScored = statsData.runs_scored || 0;
        this.clubWickets = statsData.wickets || 0;
        this.clubGames = statsData.games || 0;
        this.clubCatches = statsData.catches || 0;
      } else {
        console.log('No club stats document found!');
      }
    } catch (error) {
      console.error('Error fetching club stats:', error);
    }
  }
  async fetchUserName(userId: string) {
    console.log(`Fetching user name and type for userId: ${userId}`);
    const db = getFirestore();
    const userRef = doc(db, `Player/${userId}`);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        // Update first name if available
        if (userData.first_name) {
          this.firstName = userData.first_name;
          console.log(`User first name: ${this.firstName}`);
        } else {
          console.warn('The first_name field is missing in the user document.');
          this.firstName = 'User'; // Default or placeholder name
        }
        // Update user type if available
        this.userType = userData.type || 'Player'; // Default to 'Player' if not specified
        console.log(`User type: ${this.userType}`);
      } else {
        console.error(`No document found for userId: ${userId}`);
        // Handle the absence of a user document as needed
        this.firstName = 'User'; // Default or placeholder name
        this.userType = 'Player'; // Default user type
      }
    } catch (error) {
      console.error('Error fetching user document:', error);
      // Handle error as needed, maybe setting default values or rethrowing the error
      throw error;
    }
  }


  checkLoginStatus() {
    // Check for authentication token in sessionStorage
    const authToken = sessionStorage.getItem('authToken');
    this.isLoggedIn = !!authToken;
  }
  handleLogout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log('Logout successful');
      this.isLoggedIn = false;
      router.navigate(resolveRouterPath('login'));
    }).catch((error) => {
      console.error('Logout failed', error);
    });
  }

  updated(changedProperties: Map<PropertyKey, unknown>) {
    super.updated(changedProperties);
    // Use setTimeout to ensure the DOM has been painted
    setTimeout(() => {
      window.scrollTo({ left: document.body.scrollWidth, behavior: 'smooth' });
    }, 0);
  }
  renderWelcomeMessage() {
    const currentTime = new Date();
    const hour = currentTime.getHours();
    let greeting = "🍎 Hello";
    if (hour < 12) greeting = "🍎 Good Morning";
    if (hour >= 12 && hour <= 17) greeting = "🍎 Good Afternoon";
    if (hour > 17) greeting = "🍎 Good Evening";
    return html`
      <div class="welcome-container">
        <div class="welcome-message">
          <div class="message-text">${greeting}, ${this.firstName}! 👋</div>
        </div>
      </div>
    `;
  }

  renderSidebarLinks() {
    const isAdmin = this.userType === 'Admin';
    const isCaptain = this.userType === 'Captain';
    const isPlayer = this.userType === 'Player';
    const isFinance = this.userType === 'Finance';
    return html`
      <div class="menu-section">
        <h2>My Calendar</h2>
        ${isAdmin || isCaptain || isPlayer || isFinance ? html`<a href="${resolveRouterPath('availability')}">Availability</a>` : null}
        ${isAdmin || isCaptain ? html`<a href="${resolveRouterPath('selection')}">Selection</a>` : null}
        <a href="${resolveRouterPath('fixtures')}">Fixtures</a>
        <a href="${resolveRouterPath('results')}">Results</a>
        ${isAdmin ? html`
          <a href="${resolveRouterPath('enter-fixture')}">Enter Fixture</a>
          <a href="${resolveRouterPath('score-entry')}">Score Entry</a>
          <a href="${resolveRouterPath('stats-entry')}">Stats Entry</a>
          <a href="${resolveRouterPath('fantasy-entry')}">Fantasy Entry</a>
          <a href="${resolveRouterPath('fine-entry')}">Fine Entry</a>
          <a href="${resolveRouterPath('merge-player-data')}">Merge Player</a>
          <a href="${resolveRouterPath('stats-upload')}">Stats Upload</a>

        ` : null}
      </div>
      <div class="menu-section">
        <h2>My Performance</h2>
        ${isAdmin || isCaptain || isPlayer || isFinance ? html`<a href="${resolveRouterPath('batting')}">Batting</a>` : null}
        ${isAdmin || isCaptain || isPlayer || isFinance ? html`<a href="${resolveRouterPath('bowling')}">Bowling</a>` : null}
        ${isAdmin || isCaptain || isPlayer || isFinance ? html` <a href="${resolveRouterPath('fielding')}">Fielding</a>` : null}
        <a href="${resolveRouterPath('leaderboard')}">Leaderboard</a>
        ${isAdmin || isCaptain || isPlayer || isFinance ? html`  <a href="${resolveRouterPath('badges')}">Badges</a>
        ` : null}
      </div>
      <div class="menu-section">
        <h2>My Fantasy</h2>
        <a href="${resolveRouterPath('my-team')}">My Team</a>
        <a href="${resolveRouterPath('league')}">League</a>
        <a href="${resolveRouterPath('fantasy-rules')}">Rules</a>
      </div>
      <div class="menu-section">
      ${isAdmin || isCaptain || isPlayer || isFinance ? html` <h2>My Profile</h2>`: null}
       ${isAdmin || isCaptain || isPlayer || isFinance ? html` <a href="${resolveRouterPath('profile')}">Profile</a>` : null}
        ${isAdmin || isCaptain || isFinance ? html`<a href="${resolveRouterPath('finance')}">Finance</a>` : null}
        <a href="${resolveRouterPath('laws')}">Laws of Cricket</a>
      </div>
      ${this.isLoggedIn ? html`
        <div class="separator"></div>
        <div class="menu-section">
          <button class="logout-button" @click="${this.handleLogout}">Logout</button>
        </div>
      ` : null}
    `;
  }

  navigateToLeaderboard(stat: string) {
    router.navigate(`${resolveRouterPath('leaderboard')}?stat=${stat}`);
  }

  render() {
    return html`
      <app-header></app-header>
      <app-logo></app-logo>
      <button @click="${this.toggleSidebar}" class="hamburger">☰</button>
      <div id="sidebar" class="${this.isSidebarOpen ? 'open' : ''}">
        <button @click="${this.toggleSidebar}" class="exit-sidebar">✖️</button>
        ${this.renderSidebarLinks()}
      </div>
      <div id="mainContent" class="${this.isSidebarOpen ? 'with-sidebar' : ''}">
        <div class="top-stats first-stat">
          <div class="high-stats">
            ${this.isLoggedIn && this.firstName ? this.renderWelcomeMessage() : ''}
          </div>
          <div class="sideshow">
            <app-results-fixtures-carousel></app-results-fixtures-carousel>
          </div>
             <div class="top-stats first-stat">
          <div class="welcome-message"> Appleton's season performance so far 🎯</div>
          </div>
  <div class="stats-grid">
        ${this.renderStatCard('🏏', this.clubGames, 'Matches Played', 'games')}
        ${this.renderStatCard('📈', this.clubRunsScored, 'Runs Scored', 'runs')}
        ${this.renderStatCard('🔥', this.clubWickets, 'Wickets Taken', 'wickets')}
        ${this.renderStatCard('🤲', this.clubCatches, 'Catches Made', 'catches')}
      </div>
        </div>
      </div>
    `;
  }

  renderStatCard(icon: string, value: number, label: string, stat: string) {
    return html`
    <div class="stat-card" @click="${() => this.navigateToLeaderboard(stat)}">
      <div class="stat-icon">${icon}</div>
      <div class="stat-content">
        <div class="stat-value">${value}</div>
        <div class="stat-label">${label}</div>
      </div>
    </div>
  `;
}


  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
}
