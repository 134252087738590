import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

@customElement('app-login')
export class AppLogin extends LitElement {
  @state() private email = '';
  @state() private password = '';
  @state() private errorMsg = '';

  private auth = getAuth();

  static styles = css`
    :host {
      display: block;
      padding: 16px;
      text-align: center;
      color: Black;
    }
    input {
      width: 100%;
      padding: 8px;
      margin: 8px 0;
      box-sizing: border-box;
    }
    button {
      background-color: green;
      color: white;
      padding: 10px;
      margin: 8px 0;
      border: none;
      cursor: pointer;
      width: 100%;
    }
  button {
      background-color: green;
      color: white;
      padding: 10px;
      margin: 8px 0;
      border: none;
      cursor: pointer;
      width: 100%;
    }

    .forgot-btn {
      background-color: red;
    }

    .first-stat {
      padding-top: 115px;
    }

    button:hover {
      opacity: 0.8;
    }
    label {
      display: block;
      text-align: left;
      margin: 8px 0 4px;
    }
    .error {
      color: red;
      margin-bottom: 10px;
    }
  `;

  render() {
    return html`
      <app-header></app-header>
      <app-logo ?enableBack="${true}"></app-logo>
      <div class="top-stats first-stat">
        <h2>Welcome Back!</h2>
        <p>Please enter your details to login.</p>
        ${this.errorMsg ? html`<div class="error">${this.errorMsg}</div>` : null}
        <div>
          <label for="email">Email</label>
          <input type="email" .value="${this.email}" @input="${this.updateEmail}">
          <label for="password">Password</label>
          <input type="password" .value="${this.password}" @input="${this.updatePassword}">
          <button @click="${this.loginUser}">Login</button>
          <button @click="${this.navigateToSignUp}">Register</button>
          <button @click="${this.sendPasswordReset}" class="forgot-btn">Forgot Password?</button>

        </div>
      </div>
    `;
  }

  updateEmail(e: InputEvent) {
    const target = e.target as HTMLInputElement; // Safely cast to HTMLInputElement
    this.email = target.value;
  }

  updatePassword(e: InputEvent) {
    const target = e.target as HTMLInputElement; // Safely cast to HTMLInputElement
    this.password = target.value;
  }

  loginUser() {
    if (this.email && this.password) {
      signInWithEmailAndPassword(this.auth, this.email, this.password)
        .then(async (userCredential) => {
          console.log('Login successful', userCredential.user);
          const token = await userCredential.user.getIdToken();
          sessionStorage.setItem('authToken', token);
          window.location.href = '/home';
        })
        .catch((error) => {
          console.error('Login failed', error.code, error.message);
          this.updateErrorMsg(error.message);
        });
    } else {
      this.updateErrorMsg('Email and password must not be empty.');
    }
  }

  sendPasswordReset() {
    if (this.email) {
      sendPasswordResetEmail(this.auth, this.email)
        .then(() => {
          this.updateErrorMsg('Password reset email sent!');
        })
        .catch((error) => {
          console.error('Error sending password reset email', error.code, error.message);
          this.updateErrorMsg(error.message);
        });
    } else {
      this.updateErrorMsg('Please enter your email address.');
    }
  }

  private updateErrorMsg(msg: string) {
    this.errorMsg = msg;
    setTimeout(() => {
      this.errorMsg = '';
    }, 10000);
  }

  navigateToSignUp() {
    window.location.href = '/sign-up';
  }
}
