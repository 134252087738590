import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../../src/router';

import '@shoelace-style/shoelace/dist/components/button/button.js';




@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: Boolean }) enableBack: boolean = false;
  @property({ type: String }) profilePicUrl: string = '';


  connectedCallback() {
    super.connectedCallback();
    // Load the profile picture URL from localStorage
    this.profilePicUrl = localStorage.getItem('profilePicUrl') || '';
  }


  static styles = css`
  header {
    display: flex;
    justify-content: center; /* Center content */
    align-items: center;
    background: var(--app-color-primary);
    color: white;
    padding: 0 16px;
    position: fixed;
    left: 0;
    top: 0;
    height: 6em;
    width: 100%;
    -webkit-app-region: drag;
    background-image: url('/assets/images/mainbackground.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 7;
  }

  #back-button-block {
    position: absolute;
    left: 16px; /* Align back button to the start */
    top: 50%;
    transform: translateY(-50%);
  }



  #profile-pic-link img {
    height: 4em; /* Control the size of the image */
    border-radius: 50%; /* Optional: makes the image round */
    object-fit: cover; /* Ensure the image covers the area without distortion */
  }

  #title {
    position: absolute;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
  }

  @media (prefers-color-scheme: light) {
    header {
      color: black;
    }
    nav a {
      color: initial;
    }
  }
  .back-button {
    background-color: #206e3a; /* Your app's theme color */
    color: white;
    padding: 10px 15px;
    margin-bottom: 10px; /* Spacing below the button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    z-index: 8;
    position: absolute;
    top: 20px;
    left: 20px;
  }
`;


render() {
  return html`
    <header>
      <!-- Back Button -->
      <div id="back-button-block">
        ${this.enableBack ? html`<sl-button variant="primary" class="back-button" href="${resolveRouterPath('/')}">Back</sl-button>` : ''}
      </div>


    </header>
  `;
}



}

