import { css } from 'lit';

export const styles = css`
  @media(min-width: 1000px) {
    sl-card {
      max-width: 70vw;
    }
  }

  main {
    margin-top: 80px;
  }



  /* Adding Sidebar Styles */
  #sidebar {
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    left: 0;
    top: 0;
    width: 200px; /* Set the width of the sidebar */
    height: 100%;
    background-color: #f1f1f1; /* Sidebar background color */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }

  #sidebar a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
  }

  #sidebar a:hover {
    background-color: #555; /* Add a hover effect to the links */
    color: white;
  }

  #mainContent {
    margin-left: 200px; /* Add a left margin to the main content to prevent overlap with the sidebar */
    padding: 20px;
  }
`;
