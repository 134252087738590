// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDajP2PUFvM87E4fj7FZ42_TWqVXgckhUU",
  authDomain: "appletoncc-6261b.firebaseapp.com",
  databaseURL: "https://appletoncc-6261b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "appletoncc-6261b",
  storageBucket: "appletoncc-6261b.appspot.com",
  messagingSenderId: "652074739848",
  appId: "1:652074739848:web:2444ac74b8e6bef226973d",
  measurementId: "G-LG4W1W5MLX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);