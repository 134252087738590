import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, getDocs, collection, query, orderBy } from 'firebase/firestore';
import { resolveRouterPath } from '../router';
import Chart from 'chart.js/auto';

interface Game {
  id: string;
  label: string;
  data: any; // You can replace 'any' with a more specific type that matches your game data structure
}

@customElement('app-batting')
export class AppBatting extends LitElement {
  @state() private userData: any = null;
  @state() private isLoading: boolean = true;
  @state() private gamesList: Game[] = [];
  @state() private selectedGameStats: any = null;
  private battingChart?: any;
  private dismissalPieChart?: any;
  private distributionPieChart?: any;


  private db = getFirestore();
  private auth = getAuth();

  static styles = css`
    :host {
      display: block;
      max-width: 800px;
      margin: auto;
      padding: 20px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      background: white;
    }
    .top-stats {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .first-stat {
      padding-top: 115px; /* Adjust the value based on your header's height */
    }
    .stats-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 10px;
    }
    .chart-container {
      margin: 20px 0;
      padding: 10px;
      background: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    canvas {
      max-width: 100%;
      border-radius: 8px;
    }
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 20px;
      margin: 20px 0;
    }
    .stat-card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);
      text-align: center;
    }

    .stat-label {
      font-size: 0.9em;
      color: #666;
      margin-bottom: 5px;
    }

    .stat-value {
      font-size: 1.2em;
      font-weight: bold;
      color: #333;
    }

    .chart-link-button {
      display: inline-block;
      padding: 10px 20px;
      margin: 5px;
      border: none;
      border-radius: 5px;
      background-color: #206e3a;
      color: white;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
    }

    .chart-link-button:hover {
      background-color: #105e2a;
    }

    select {
      padding: 10px 30px 10px 15px;
      margin-right: 10px; /* Spacing to the right of the dropdown, adjust as needed */
      background-color: #206e3a;
      border: 1px solid #ddd; /* Light grey border */
      border-radius: 5px;
      font-family: inherit; /* To match the font style of the app */
      font-size: 1em; /* Adjust size as needed */
      color: white;
      cursor: pointer;
      outline: none; /* Removes the default focus outline */
      appearance: none; /* Removes default system dropdown arrow */
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
      background-repeat: no-repeat;
      background-position: right 5px center;
      background-size: 22px; /* Adjust arrow size */
      -webkit-print-color-adjust: exact; /* Ensures the arrow is visible when printing on Chrome */
      color-adjust: exact; /* Ensures the arrow is visible when printing on other browsers */
    }

    select:hover {
      border-color: #206e3a; /* Darker border on hover to match button hover */
    }

    select:focus {
      border-color: #105e2a; /* Even darker border for focus */
      box-shadow: 0 0 0 2px rgba(16, 94, 42, 0.2); /* Subtle focus glow */
    }

    /* Style to visually disable the select dropdown */
    select:disabled {
      color: #ccc;
      background-color: #f5f5f5;
      cursor: default;
    }

    /* You may also style the option elements */
    option {
      padding: 10px; /* Options padding */
      background: white; /* Background color for options */
      color: #333; /* Text color for options */
    }

    .stats-heading-container {
      text-align: center;
      padding: 20px;
      margin-bottom: 30px; /* Space before the rest of the content */
    }

    .stats-heading {
      font-size: 2.5rem; /* Adjust size as needed */
      color: #206e3a; /* Color that fits the app's theme */
      margin: 0; /* Adjust as needed */
    }

    .stats-subheading {
      font-size: 1rem; /* Smaller than the main heading */
      color: #333; /* A neutral darker shade */
    }

  `;

  connectedCallback(): void {
    super.connectedCallback();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.fetchUserData(user.uid).then(() => {
          this.fetchAndInitializeChart();
        });
        this.fetchGamesList(user.uid); // New method to fetch list of games
      }
    });
  }

  async fetchGamesList(userId: string) {
    const matchesRef = collection(this.db, `Player/${userId}/matches`);
    const q = query(matchesRef, orderBy('date', 'asc'));
    const querySnapshot = await getDocs(q);
    const gamesList: Game[] = []; // Explicitly type this as an array of Game objects
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      gamesList.push({
        id: doc.id,
        label: `${data.opponent} - ${new Date(data.date.seconds * 1000).toDateString()}`,
        data: data // Assuming 'data' matches the structure of your game data
      });
    });
    this.gamesList = gamesList;
  }

  async fetchGameStats(gameId: string) {
    const user = this.auth.currentUser;
    if (!user) return;

    const gameRef = doc(this.db, `Player/${user.uid}/matches`, gameId);
    const docSnap = await getDoc(gameRef);
    if (docSnap.exists()) {
      this.selectedGameStats = docSnap.data();
      // Call chart initialization here with selected game stats
      this.initializeChartsWithGameStats();
    }
  }

  initializeChartsWithGameStats(): void {
    if (!this.selectedGameStats) return;
    this.updateBattingChart();
    this.updateDismissalPieChart();
    this.updateDistributionPieChart();
  }

  updateBattingChart(): void {
    const labels = ['Selected Game'];
    const dataPoints = [this.selectedGameStats.runs_scored];
    const label = 'Runs Scored';

    this.generateChart('battingChart', labels, dataPoints, label);
  }

  updateDismissalPieChart(): void {
    const dismissalsData = [
      this.selectedGameStats.bowled,
      this.selectedGameStats.caught,
      this.selectedGameStats.stumped,
      this.selectedGameStats.lbw,
      this.selectedGameStats.run_out,
      this.selectedGameStats.other,
    ];
    const dismissalLabels = ['Bowled', 'Caught', 'Stumped', 'LBW', 'Run Out', 'Other'];
    const dismissalColors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#C9CB3A'];

    this.generatePieChart('dismissalPieChart', dismissalLabels, dismissalsData, dismissalColors);
  }

  updateDistributionPieChart(): void {
    const distributionData = [
      this.selectedGameStats.total_dots,
      this.selectedGameStats.total_ones,
      this.selectedGameStats.total_twos,
      this.selectedGameStats.total_threes,
      this.selectedGameStats.total_fours,
      this.selectedGameStats.total_sixes
    ];
    const distributionLabels = ['Dot Balls', 'Singles', 'Twos', 'Threes', 'Fours', 'Sixes'];
    const distributionColors = ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#AC64AD'];

    this.generatePieChart('distributionPieChart', distributionLabels, distributionData, distributionColors);
  }





  async fetchUserData(userId: string) {
    const userRef = doc(this.db, `Player/${userId}`);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      this.userData = docSnap.data();
    }
    this.isLoading = false;
  }

  async fetchAndInitializeChart() {
    const user = this.auth.currentUser;
    if (!user) return;

    const matchesRef = collection(this.db, `Player/${user.uid}/matches`);
    const q = query(matchesRef, orderBy('date', 'asc'));

    const querySnapshot = await getDocs(q);
    const dataPoints: number[] = [];
    const labels: string[] = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      dataPoints.push(data.runs_scored);
      labels.push(`${data.opponent} - ${new Date(data.date.seconds * 1000).toDateString()}`);
    });

    this.generateChart('battingChart', labels, dataPoints, 'Runs Scored');


    const dismissalsData = [this.userData.bowled, this.userData.caught, this.userData.stumped, this.userData.lbw, this.userData.run_out, this.userData.other];
    const dismissalLabels = ['Bowled', 'Caught', 'Stumped', 'LBW', 'Run Out', 'Other'];
    const dismissalColors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange']; // Color for each slice
    this.generatePieChart('dismissalPieChart', dismissalLabels, dismissalsData, dismissalColors);

    const distributionData = [this.userData.total_dots, this.userData.total_ones, this.userData.total_twos, this.userData.total_threes, this.userData.total_fours, this.userData.total_sixes];
    const distributionLabels = ['Dot', '1s', '2s', '3s', '4s', '6s'];
    const distributionColors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange']; // Color for each slice
    this.generatePieChart('distributionPieChart',distributionLabels, distributionData, distributionColors);
  }

  generateChart(canvasId: string, labels: string[], dataPoints: number[], label: string) {
    const canvas = this.shadowRoot?.getElementById(canvasId) as HTMLCanvasElement;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    if (this.battingChart) this.battingChart.destroy(); // Clear any existing chart instance

    this.battingChart = new Chart(context, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: label,
          data: dataPoints,
          backgroundColor: 'rgba(7, 103, 6, 0.4)',
          borderColor: 'rgba(7, 103, 6, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  generatePieChart(canvasId: string, labels: string[], dataPoints: number[], backgroundColors: string[]) {
    const canvas = this.shadowRoot?.getElementById(canvasId) as HTMLCanvasElement;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    // Destroy the existing chart instance if it exists
    if (canvasId === 'dismissalPieChart' && this.dismissalPieChart) {
      this.dismissalPieChart.destroy();
    } else if (canvasId === 'distributionPieChart' && this.distributionPieChart) {
      this.distributionPieChart.destroy();
    }

    const newPieChart = new Chart(context, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          data: dataPoints,
          backgroundColor: backgroundColors,
        }]
      },
      options: {
        aspectRatio: 3
        // Specify options here as needed, ensuring they match the expected types
      },
    });

    // Assign the new chart instance to the appropriate property
    if (canvasId === 'dismissalPieChart') {
      this.dismissalPieChart = newPieChart;
    } else if (canvasId === 'distributionPieChart') {
      this.distributionPieChart = newPieChart;
    }
  }

  renderGamesDropdown(): TemplateResult {
    return html`
    <div class="top-stats first-stat">
    <div class="stats-heading-container">
    <h1 class="stats-heading">My Batting Statistics 🏏</h1>
    <p class="stats-subheading">Detailed insights into your batting performance.</p>
  </div>
  </div>


        <select @change="${this.handleGameSelection}" class="game-select-dropdown">
          <option value="">All games</option>
          ${this.gamesList.map(
            game => html`<option value="${game.id}">${game.label}</option>`
          )}
        </select>

    `;
  }

  handleGameSelection(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedGameId = selectElement.value;

    if (selectedGameId) {
      // Fetch and display stats for the selected game
      this.fetchGameStats(selectedGameId);
    } else {
      // "Select a game" option was chosen, reset selectedGameStats to null to display overall stats
      this.selectedGameStats = null;
      this.fetchAndInitializeChart();
    }
  }




  renderLoading(): TemplateResult {
    return html`<p>Loading...</p>`;
  }

  renderData(): TemplateResult {
    const statsSource = this.selectedGameStats || this.userData;
    const runsScored = statsSource.runs_scored || 0;
    const totalOuts = statsSource.total_outs || 0;
    const ballsFaced = statsSource.balls_faced || 0;


    let batting_average = '\u221E';
    if (totalOuts > 0) {
      batting_average = (runsScored / totalOuts).toFixed(2);
    }

    let strikeRate = '\u221E';
    if (ballsFaced > 0) {
      strikeRate = ((runsScored / ballsFaced) * 100).toFixed(2);
    }

    return html`
  <app-header></app-header>
  <app-logo ?enableBack="${true}"></app-logo>

  <div class="stats-grid">
    ${this.renderStatCard('Total Runs', statsSource.runs_scored)}
    ${this.renderStatCard('Balls Faced', statsSource.balls_faced)}
    ${this.renderStatCard('Games Played', statsSource.games)}
    ${this.renderStatCard('Batting Average', batting_average)}
    ${this.renderStatCard('Strike Rate', strikeRate)}
    ${this.renderStatCard('50s', statsSource.fifties)}
    ${this.renderStatCard('100s', statsSource.hundreds)}
    ${this.renderStatCard('Not Outs', statsSource.total_not_outs)}
    ${this.renderStatCard('Retirements', statsSource.isRetired)}
    ${this.renderStatCard('Ducks', statsSource.ducks)}
    ${this.renderStatCard('Golden Ducks', statsSource.golden_ducks)}
    ${this.renderStatCard('Outs', statsSource.total_outs)}
  </div>
  <div class="charts-container">
  <a href="${resolveRouterPath('full-batting-chart')}" class="chart-link-button">View Full Batting Chart</a>
  <a href="${resolveRouterPath('full-dismissals-chart')}" class="chart-link-button">View Full Dismissal Pie Chart</a>
  <a href="${resolveRouterPath('full-distribution-chart')}" class="chart-link-button">View Full Distribution Pie Chart</a>
</div>
`;

  }

  renderStatCard(label: string, value: any): TemplateResult {
    return html`
      <div class="stat-card">
        <div class="stat-label">${label}:</div>
        <div class="stat-value">${value}</div>
      </div>
    `;
  }



  render(): TemplateResult {
    return html`
      <div>
        ${this.isLoading ? this.renderLoading() : html`
          <div>${this.renderGamesDropdown()}</div>
          ${this.renderData()}
        `}
      </div>
    `;
  }



}
