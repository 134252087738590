import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { getFirestore, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { resolveRouterPath, router } from '../router';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

interface CarouselItem {
  title: string;
  description: string;
}

@customElement('app-results-fixtures-carousel')
export class AppResultsFixturesCarousel extends LitElement {
  @state() private items: CarouselItem[] = [];
  @state() private activeIndex = 0;

  static styles = css`
    .carousel-container {
      position: relative;
      max-width: 800px;
      margin: auto;
      overflow: hidden;
      background: linear-gradient(to bottom right, #f0fff4, #fffbeb);
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    .carousel-slide {
      display: flex;
      transition: transform 0.5s ease;
    }
    .carousel-item {
      min-width: 100%;
      text-align: center;
      padding: 2rem;
      box-sizing: border-box;
    }
    .carousel-item h2 {
      font-size: 1.875rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }
    .carousel-item p {
      font-size: 1rem;
      line-height: 1.5;
      color: #4b5563;
    }
    .carousel-item p div {
      margin-bottom: 0.5rem;
    }
    .carousel-item p strong {
      font-weight: 600;
      color: #111827;
    }
    .prev, .next {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #eab308;
      color: white;
      border: none;
      padding: 0.75rem;
      border-radius: 9999px;
      font-size: 1.125rem;
      transition: background-color 0.3s;
    }
    .prev:hover, .next:hover {
      background-color: #ca8a04;
    }
    .prev { left: 1rem; }
    .next { right: 1rem; }
    .carousel-indicators {
      text-align: center;
      padding-top: 1.25rem;
    }
    .dot {
      display: inline-block;
      cursor: pointer;
      height: 0.75rem;
      width: 0.75rem;
      margin: 0 0.25rem;
      background-color: #d1d5db;
      border-radius: 50%;
      transition: background-color 0.6s ease;
    }
    .dot.active {
      background-color: #eab308;
    }
    .availability-button {
      margin-top: 1.25rem;
      padding: 0.625rem 1.25rem;
      font-size: 1rem;
      background-color: #22c55e;
      color: white;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: background-color 0.3s;
    }
    .availability-button:hover {
      background-color: #16a34a;
    }
    .result-icon {
      font-size: 1.5rem;
      margin-left: 10px;
    }
  `;
  connectedCallback() {
    super.connectedCallback();
    this.fetchData();
  }

  async fetchData() {
    const db = getFirestore();
    const today = new Date();
    const fixturesRef = collection(db, 'Fixtures');

    let lastResult: CarouselItem = {
      title: 'No recent results',
      description: 'There are no recent results to display.'
    };
    let nextFixture: CarouselItem = {
      title: 'No upcoming fixtures',
      description: 'There are no upcoming fixtures to display.'
    };

    const upcomingQuery = query(fixturesRef, where('date', '>', today), orderBy('date', 'asc'), limit(1));
    const lastResultQuery = query(fixturesRef, where('date', '<', today), orderBy('date', 'desc'), limit(1));

    try {
      const [upcomingSnapshot, lastResultSnapshot] = await Promise.all([
        getDocs(upcomingQuery),
        getDocs(lastResultQuery)
      ]);

      upcomingSnapshot.forEach(doc => {
        const data = doc.data();
        console.log('Upcoming match data:', data);
        nextFixture = {
          title: `Next Match: ${data.opponent}`,
          description: `
            <div><strong>Date:</strong> ${this.formatDate(data.date.toDate())}</div>
            <div><strong>Start time:</strong> ${data.time}</div>
            <div><strong>Venue:</strong> ${data.venue}</div>
            <div><strong>Overs:</strong> ${data.overs}</div>
          `
        };
      });

      lastResultSnapshot.forEach(doc => {
        const data = doc.data();
        console.log('Last result data:', data);
        lastResult = {
          title: `Latest Result: ${data.opponent} - ${data.win_or_loss}`,
          description: `
            <div><strong>Appleton:</strong> ${data.appleton_score}/${data.appleton_outs}</div>
            <div><strong>Opponent:</strong> ${data.opponent_score}/${data.opponent_outs}</div>
            <div><strong>Date:</strong> ${this.formatDate(data.date.toDate())}</div>
            <div><strong>Venue:</strong> ${data.venue}</div>
          `
        };
      });

      this.items = [lastResult, nextFixture];
    } catch (error) {
      console.error("Error fetching fixtures and results:", error);
    }
  }

  formatDate(date: Date) {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  }

  prevSlide() {
    this.activeIndex = (this.items.length + this.activeIndex - 1) % this.items.length;
  }

  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.items.length;
  }

  selectSlide(index: number) {
    this.activeIndex = index;
  }

  getResultIcon(result: string) {
    switch (result.toLowerCase()) {
      case 'won':
        return '🏆'; // Trophy emoji for win
      case 'loss':
        return '❌'; // Cross emoji for loss
      case 'drawn':
        return '⚖️'; // Balance emoji for draw
      case 'abandoned':
        return '🚫'; // No entry emoji for abandoned
      default:
        return '❓'; // Question mark emoji for unknown result
    }
  }

  renderItems() {
    return this.items.map((item, index) => {
      const result = item.title.split(' - ')[1]?.toLowerCase() || '';

      // Replace 'undefined' with 'Stats not yet updated'
      const updatedDescription = item.description.replace(/undefined/g, 'Stats not yet updated');

      return html`
        <div class="carousel-item">
          <h2 style="color: ${result === 'won' ? 'green' : result === 'loss' ? 'red' : 'black'};">
            ${item.title.replace('undefined', 'Stats not yet updated')}
            <span class="result-icon">${this.getResultIcon(result)}</span>
          </h2>
          <p>${unsafeHTML(updatedDescription)}</p>
          ${index === 1 ? html`
            <button @click=${this.navigateToAvailability} class="availability-button">
              Select Availability
            </button>
          ` : ''}
        </div>
      `;
    });
  }


  navigateToAvailability() {
    router.navigate(resolveRouterPath('availability'));
  }

  renderDots() {
    return html`
      <div class="carousel-indicators">
        ${this.items.map((_, index) => html`
          <span class="dot ${this.activeIndex === index ? 'active' : ''}" @click="${() => this.selectSlide(index)}"></span>
        `)}
      </div>
    `;
  }

  render() {
    const offset = this.activeIndex * -100;
    return html`
      <div class="carousel-container">
        <div class="carousel-slide" style="transform: translateX(${offset}%)">
          ${this.renderItems()}
        </div>
        <button class="prev" @click="${this.prevSlide}">&#10094;</button>
        <button class="next" @click="${this.nextSlide}">&#10095;</button>
        ${this.renderDots()}
      </div>
    `;
  }
}