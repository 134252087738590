import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../../src/router';

import '@shoelace-style/shoelace/dist/components/button/button.js';
@customElement('app-logo')
export class AppLogo extends LitElement {


  @property({ type: Boolean}) enableBack: boolean = false;

  static styles = css`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--app-color-primary);
    color: white;
    height: 4em;
    padding-left: 16px;
    padding-top: 12px;
    z-index: 8;

    position: fixed;
    left: env(titlebar-area-x, 0);
    top: env(titlebar-area-y, 0);
    height: env(titlebar-area-height, 100px); /* Adjusted height */
    width: env(titlebar-area-width, 100%);
    -webkit-app-region: drag;

    /* Corrected path and adjusted background properties */
    background-image: url('/assets/images/appletonlogo.png'); /* Ensure the path is correct */
    background-size: 70px 160px; /* Scale down the logo, adjust as needed */
    background-position: right 20px top -40px; /* Position top right with some padding */
    background-repeat: no-repeat;
  }

  header h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
  }

  nav a {
    margin-left: 10px;
  }
  #back-button-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 12em;
  }

  @media(prefers-color-scheme: light) {
    header {
      color: black;
    }

    nav a {
      color: initial;
    }
  }
`;

  render() {
    return html`
      <header>

        <div id="back-button-block">
          ${this.enableBack ? html`<sl-button href="${resolveRouterPath()}">
            Back
          </sl-button>` : null}

          <h1>${this.title}</h1>
        </div>
      </header>
    `;
  }
}